.swiper {
    width: 100%;
    height: 100%;
  }
  
  /* .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    top: 98px;
    right: -500px;
    z-index: 99;
    display: none;    
}
  
  .swiper-pagination-bullet {
    position: relative;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .swiper-pagination-bullet-active {
    color: #FF7300;
    background: #FF7300;
  }

 