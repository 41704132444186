@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TOMMY";
  src: url("../public/fonts/TOMMY.otf");
}

@font-face {
  font-family: "TOMMY_BOLD";
  src: url("../public/fonts/TOMMY_BOLD.otf");
}



::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-[#FF7300] dark:bg-[#FF7300];
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400 dark:bg-gray-700;
}

body{
  padding: 0;
  margin: 0;
}